
function Privacy() {
    return (
        <div className=" overflow-y-scroll min-h-screen bg-gray-300 flex items-center large-laptop:px-96">
            <div className="w-[700px] mt-28 mb-28">
                <h1 className="text-xl font-bold">Privacy Policy for Grid Elevated™ App</h1>
                <p>Last updated: 09/13/2024</p>
                <h2 className="mt-5 text-lg font-bold">1. Introduction</h2>
                <p>Welcome to the Grid Elevated™ app ("we," "our," or "us"). We are committed to protecting your privacy and providing a safe, optimized EV charging experience. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application.</p>
                <h2 className="mt-5 text-lg font-bold">2. Information We Collect</h2>
                <p className="font-medium">We collect the following types of information:</p>
                <ul className="mt-1">
                    <li>- Account Information: Personal details necessary for account creation and management.</li>
                    <li>- Vehicle Information: Details about your electric vehicle to optimize your charging experience.</li>
                    <li>- Charging Data: Information related to your charging sessions.</li>
                    <li>- Camera Access: Used solely for recognizing charger IDs.</li>
                    <li>- Location Data: Temporary access for the charger map feature.</li>
                    <li>- License Plate Information: Collected during registration for accountability purposes.</li>
                </ul>
                <h2 className="mt-5 text-lg font-bold">3. How we use your information</h2>
                <p className="font-medium">We use the collected information for the following purposes:</p>
                <ul className="mt-1">
                    <li>- To provide and optimize your EV charging experience.</li>
                    <li>- To create and manage your account.</li>
                    <li>- To display nearby charging stations on the map.</li>
                    <li>- To ensure accountability between users and IntelliCharge™ charging equipment.</li>
                </ul>
                <h2 className="mt-5 text-lg font-bold">4. Data Security</h2>
                <p className="font-medium">We implement industry-standard security measures to protect your information:</p>
                <ul className="mt-1">
                    <li>- All account information is securely stored by Grid Elevated™.</li>
                    <li>- No third-party access is enabled for your personal data.</li>
                </ul>
                <h2 className="mt-5 text-lg font-bold">5. Data Sharing and Disclosure</h2>
                <p>We do not share your personal information with third parties.</p>
                <h2 className="mt-5 text-lg font-bold">6. Your Privacy Rights</h2>
                <p className="font-medium">You have the right to:</p>
                <ul className="mt-1">
                    <li>- Access, correct, or delete your personal information.</li>
                    <li>- Opt-out of certain data collection or use.</li>
                </ul>
                <p className="mt-1">For any privacy-related requests, please contact us at <span className="text-blue-600">info@gridelevated.com</span>.</p>
                <h2 className="mt-5 text-lg font-bold">7. Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
                <h2 className="mt-5 text-lg font-bold">8. Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p className="mt-1"><span className="text-blue-600">info@gridelevated.com</span>.</p>
            </div>
        </div>
    )
}

export default Privacy